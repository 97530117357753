import { createStore } from 'redux'
import reducers from './reducers'

const store = createStore(
  reducers,
  (window as ReduxDevToolsWindow).__REDUX_DEVTOOLS_EXTENSION__?.()
)

export default store

type ReduxDevToolsWindow = Window & typeof globalThis & ({__REDUX_DEVTOOLS_EXTENSION__: Function})
