import {FC, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {state} from '../redux/reducers'
import {Button, Image, ButtonGroup} from 'react-bootstrap'
import {Gear} from 'react-bootstrap-icons'
import {unsetUserSessionCart} from '../redux/actions'
import UserSettings from './settings/user/UserSettings'
import BeverageOverview from "./admin/BeverageOverview";


const UserButton: FC = () => {
    const user = useSelector((state: state) => state.user)
    const [showMenu, setShowMenu] = useState(false)
    const toggleMenu = () => setShowMenu(!showMenu)
    const [showUserSettings, setShowUserSettings] = useState(false)
    const toggleUserSettings = () => setShowUserSettings(!showUserSettings)
    const [showAdminSettings, setShowAdminsSettings] = useState(false)
    const toggleAdminSettings = () => setShowAdminsSettings(!showAdminSettings)
    const dispatch = useDispatch()

    const logout = () => dispatch(unsetUserSessionCart())

//     const darkStyle = {backgroundColor: "#303030", color: "white"};



    return (
        <>
            <div id='user-button'>
                <div className="button-wrapper" aria-controls='user-menu'>
                    <Image className="shadow-lg"
                       src={user?.img}
                       alt="Profilbild"
                       onClick={toggleMenu}
                    />
                    <Gear onClick={toggleMenu}/>
                </div>
                <ButtonGroup id="user-menu" aria-expanded={showMenu}>
                    <Button variant="danger" onClick={logout} id="logout">Logout</Button>
                    <Button onClick={() => console.log('App settings')} id="appsettings">
                        App-Einstellungen
                    </Button>
                     {
                        user?.isAdmin ?
                            <Button onClick={toggleAdminSettings} variant="secondary" id="show-stock">
                                Lagerbestand-Übersicht
                            </Button> : ''
                    }
                    <Button onClick={toggleUserSettings} id="usersettings">
                        User-Einstellungen
                    </Button>
                </ButtonGroup>
            </div>
            <UserSettings show={showUserSettings} hide={toggleUserSettings}/>
            <BeverageOverview show={showAdminSettings} hide={toggleAdminSettings}/>
        </>
    )
}

export default UserButton;