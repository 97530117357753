import { NullableReducer } from '.'

const sessionReducer: NullableReducer<string> = (state = localStorage.getItem('session'), action) => {
    switch (action.type) {
        case 'SESSION_SET':
            action.payload != null ? localStorage.setItem('session', action.payload) : localStorage.removeItem('session')
            return action.payload as string|null
        case 'SESSION_UNSET':
        case 'USER_SESSION_UNSET':
        case 'USER_SESSION_CART_UNSET':
            localStorage.removeItem('session')
            return null
        default:
            return state
    }
}

export default sessionReducer
