import {ChangeEvent, FC, FormEventHandler, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {state} from '../../../redux/reducers'
import {setUser} from "../../../redux/actions";
import {Alert, Button, FloatingLabel, Form, Image, Modal} from 'react-bootstrap'
import {backendURL} from '../../../helpers'
import Portrait from './Portrait'
import {LoginUser} from "../../../PloneApi";

const UserSettings: FC<{ show: boolean, hide: () => void }> = ({show, hide}) => {
    const [user, session] = [
        useSelector((state: state) => state.user)!,
        useSelector((state: state) => state.session)!
    ]
    const [email, setEmail] = useState<string>(user.email)
    const [fullname, setFullname] = useState<string>(user.displayName)
    const [portrait, setPortrait] = useState<Portrait>()
    const [statistics, setStatistics] = useState<boolean>(user.showStatistics)
    const [error, setError] = useState(false)

    const dispatch = useDispatch()

    const handleSubmit: FormEventHandler<HTMLFormElement> = event => {
        event.preventDefault()

        const body: ApiBody = {}
        if (email !== user.email) body.email = email
        if (fullname !== user.displayName) body.fullname = fullname
        if (statistics !== user.showStatistics) body.show_statistics = statistics
        if (portrait) {
            body.portrait = portrait.toObj()
            setPortrait(undefined)
        }

        fetch(`${backendURL}/@users/${user.username}`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
            redirect: 'follow'
        }).then(async (r) => {
            if (r.status.toString()[0] !== '2') {
                console.error('Request failed', r, await r.text())
                setError(true);
            } else {
                const changedUser: LoginUser = {
                    email: email,
                    displayName: fullname,
                    img: user.img + "?" + Date.now(),
                    username: user.username,
                    showStatistics: statistics,
                    isAdmin: user.isAdmin
                }
                dispatch(setUser(changedUser))
            }
        })
    }

    return (
        <Modal show={show} onHide={hide}>
            {
                error ? <Alert variant={"alert"}>Fehler bei der Aktualisierung</Alert> : <></>
            }
            <Modal.Header closeButton>
                <Modal.Title>User setting</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <h3>Profile</h3>
                    <Image src={user.img} style={{maxWidth: "100%", maxHeight: "400px"}} />
                    <FloatingLabel controlId="fullname" label="Fullname">
                        <Form.Control type="text"
                                      value={fullname}
                                      onChange={({target: {value}}) => setFullname(value)} />
                    </FloatingLabel>
                    <FloatingLabel controlId="portrait" label="Profile Picture">
                        <Form.Control type="file"
                                      onChange={async ({target: {files}}: ChangeEvent<HTMLInputElement>) => setPortrait(await Portrait.fromFile(files![0]))} />
                    </FloatingLabel>
                    <FloatingLabel controlId="email" label="Email address">
                        <Form.Control type="email"
                                      value={email}
                                      onChange={({target: {value}}) => setEmail(value)} />
                    </FloatingLabel>
                    <h3>Global</h3>
                    <Form.Check
                        type="switch"
                        id="showStatistics"
                        label="Show Statistics"
                        checked={statistics}
                        onChange={({target: {checked}}) => {
                            setStatistics(checked)
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" onClick={hide}>Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default UserSettings

interface ApiBody {
    email?: string
    fullname?: string
    portrait?: ReturnType<Portrait['toObj']>
    show_statistics?: boolean
}