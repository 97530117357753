import {FC, useEffect, useState} from 'react'
import {backendURL} from '../../helpers'
import {useSelector} from 'react-redux'
import {state} from '../../redux/reducers'
import {Button, Modal, ModalBody, Table} from 'react-bootstrap'
import BeverageRow from "./BeverageRow";

const BeverageOverview: FC<{ show: boolean, hide: () => void }> = ({show, hide}) => {
    const [beverageList, setBeverageList] = useState([])
    const [showShouldOrdered, setShowShouldOrdered] = useState(true)
    const session = useSelector((state: state) => state.session)!

    const switchTab = (st : boolean) => {
        setShowShouldOrdered(st)
    };


    useEffect(() => {
        fetch(`${backendURL}/${showShouldOrdered ? `get_beverages` : `get_beverages_should_ordered`}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json',
                'Redirect': 'follow'
            }
        }).then(r => r.json()).then((items) => {
            setBeverageList(items)
        }).catch(e => console.error(e))
    }, [session, showShouldOrdered])

    return (
        <Modal show={show} onHide={hide} dialogClassName={"modal-xl"}>
            <Modal.Header closeButton>
                <Modal.Title>Lagerbestand Übersicht</Modal.Title>


            </Modal.Header>
            <ModalBody className="d-flex justify-content-end" style={{columnGap: "8px"}}>
                <Button onClick={() => switchTab(true)} variant={showShouldOrdered ? `primary` : `secondary`}
                        id="usersettings">
                    Zeige Alle Getränke
                </Button>
                <Button onClick={() => switchTab(false)}
                        variant={showShouldOrdered ? `secondary` : `primary`} id="usersettings">
                    Zeige zu Bestellende Getränke
                </Button>
            </ModalBody>

            <ModalBody className={"beverageOverview"}>
                <Table hover={true} responsive>
                    <thead>
                    <tr>
                        {['Name', 'Lagerbestand', 'Veranschaulicht', 'Benötigt', 'Nachbestell Menge', 'Nachbestell Preis', 'Optionen'].map(col =>
                            <th>{col}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {
                        beverageList?.map(beverage => <BeverageRow beverage={beverage} tab={showShouldOrdered}/>) ??
                        <tr>
                            <td>ww</td>
                        </tr>
                    }
                    </tbody>
                </Table>
            </ModalBody>
        </Modal>
    )
}

export default BeverageOverview
