import { LoginUser as User, CartBeverage } from '../../PloneApi'

const createAction = (type: string) => {
    return () => {
        return {
            type
        }
    }
}

const createActionWithData = <T> (type: string) => {
    return (data: T) => {
        return {
            type,
            payload: data
        }
    }
}

export const setSession = createActionWithData<string>('SESSION_SET')

export const unsetSession = createAction('SESSION_UNSET')

export const setUser = createActionWithData<User>('USER_SET')

export const unsetUser = createAction('USER_UNSET')

export const unsetUserSession = createAction('USER_SESSION_UNSET')

export const addToCart = createActionWithData<CartBeverage>('CART_ADD')

export const removeFromCart = createActionWithData<string>('CART_REMOVE')

export const updateCartAmount = createActionWithData<{ index: number, amount: number }>('CART_UPDATE_AMOUNT')

export const updateCartUser = createActionWithData<{ index: number, user: string }>('CART_UPDATE_USER')

export const emptyCart = createAction('CART_EMPTY')

export const unsetUserSessionCart = createAction('USER_SESSION_CART_UNSET')