import {FC, useState} from 'react'
import {Beverage} from "../../PloneApi";
import {Button, ButtonGroup, Form, ProgressBar} from "react-bootstrap";
import {backendURL} from "../../helpers";
import {useSelector} from "react-redux";
import {state} from '../../redux/reducers'

const BeverageRow: FC<{ beverage: Beverage, tab: boolean }> = ({beverage, tab}) => {
    const session = useSelector((state: state) => state.session)!
    const [getsOrdered, setGetsOrdered] = useState(beverage.shouldordered)

    const setShouldOrdered = (ean: string, st : boolean ) => {
        setGetsOrdered(st)
        fetch(`${backendURL}/set_should_ordered`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json',
                'Redirect': 'follow'
            },
            body: JSON.stringify({ean})
        }).then(async (r) => {
            if (r.status.toString()[0] === "2")
                return await r.json()
            console.error(r, await r.text())
        }).then((response: Beverage) =>
            beverage = response

        )
    }
    return <tr>
        <td style={{verticalAlign: "middle"}}>
            {beverage.name}
        </td>
        <td style={{verticalAlign: "middle"}}>
            {beverage.stock}
        </td>
        <td style={{verticalAlign: "middle"}}>
            {beverage.stock / beverage.needed * 100 > 30 ?
                <ProgressBar variant="success" now={beverage.stock / beverage.needed * 100}/>
                :
                <ProgressBar variant="warning" now={beverage.stock / beverage.needed * 100}/>
            }
        </td>
        <td style={{verticalAlign: "middle"}}>
            {beverage.needed}
        </td>
        <td style={{verticalAlign: "middle"}}>
            <Form.Group controlId="formInputAmount">
                <Form.Control size="sm" type="text" placeholder={beverage.difference.toString()}/>
            </Form.Group>
        </td>
        <td style={{verticalAlign: "middle"}}>
            {beverage.orderprice}
        </td>
        <td style={{verticalAlign: "middle"}}>
            <ButtonGroup>
                {tab ?
                    ''
                    :
                    <Button variant={"primary"} size="sm" onClick={() => window.open(beverage.orderadress, "_blank")}>
                        Nachbestellen
                    </Button>
                }

                {tab ?
                    <Button variant={getsOrdered? "secondary" : "success" } size="sm" onClick={() => setShouldOrdered(beverage.ean , !getsOrdered)} disabled={getsOrdered}>
                        Hinzufügen
                    </Button>
                    :
                    <Button variant={getsOrdered? "danger" : "secondary" } size="sm" onClick={() => setShouldOrdered(beverage.ean, !getsOrdered)}>
                        Entfernen
                    </Button>

                }


            </ButtonGroup>
        </td>
    </tr>
}

export default BeverageRow