import { NullableReducer } from '.'
import { LoginUser as User } from '../../PloneApi'

const userReducer: NullableReducer<User> = (state = 'user' in localStorage ? JSON.parse(localStorage.getItem('user')!) as User : null, { type, payload }) => {
  switch (type) {
    case 'USER_SET':
      payload != null ? localStorage.setItem('user', JSON.stringify(payload)) : localStorage.removeItem('user')
      return payload as User|null
    case 'USER_UNSET':
    case 'USER_SESSION_UNSET':
    case 'USER_SESSION_CART_UNSET':
      localStorage.removeItem('user')
      return null
    default:
      return state
  }
}

export default userReducer
