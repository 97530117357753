import { useSelector } from 'react-redux'
import { state } from './redux/reducers'
import Login from './components/login/Login'
import AutoLogout from './components/login/AutoLogout'
import UserButton from './components/UserButton'
import Dashboard from './components/dashboard/Dashboard'
import { FC } from 'react'

const App: FC = () => {
    const user = useSelector((state: state) => state.user)
    const session = useSelector((state: state) => state.session)

    if (session === null || user === null) {
        return <Login />
    } else {
        return (
            <>
                <AutoLogout/>
                <Dashboard />
                <UserButton />
            </>
        )
    }
}

export default App
