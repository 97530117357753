import { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  Container,
  Form,
  Row,
  Spinner,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import { Icon, Backspace, DoorClosedFill, PersonXFill } from 'react-bootstrap-icons'
import { LoginUser } from '../../PloneApi'
import { api, fixedLengthNumber } from '../../helpers'
import { Variant } from 'react-bootstrap/types'
import { useDispatch } from 'react-redux'
import { setSession, setUser as setGlobalUser } from '../../redux/actions'

const Login: FC = () => {
  const [employeeNumber, setEmployeeNumber] = useState('')
  const [pin, setPin] = useState('')
  const [input, setInput] = useState('')
  const [users, setUsers] = useState(new Map<string, LoginUser>())
  const [user, setUser] = useState<LoginUser>()
  const [maxEmployeeNumber, setMaxEmployeeNumber] = useState<number>()
  const h2Ref = useRef<HTMLHeadingElement>(null)
  const [toasts, setToasts] = useState(new Map<number, JSX.Element>())
  const dispatch = useDispatch()

  const removeToast = (id: number) => {
    const newMap = new Map(toasts)
    newMap.delete(id)
    setToasts(newMap)
  }

  const addToast = useCallback((HeaderIcon: Icon, title: string, body: string, bg: Variant = 'danger') => {
    const newMap = new Map(toasts),
      id = Date.now()
    newMap.set(id, (
      <Toast key={id} bg={bg} autohide onClose={() => removeToast(id)}>
        <Toast.Header>
          <HeaderIcon className='text-white bg-dark rounded p-1 me-2' style={{ height: '20px', width: '20px' }}/>
          <strong className="me-auto">{title}</strong>
        </Toast.Header>
        <Toast.Body>{body}</Toast.Body>
      </Toast>
    ))
    setToasts(newMap)
    // eslint-disable-next-line
  }, [])

  // Load users from Plone Backend on component mount
  useEffect(() => {
    api<LoginUser[]>('get_all_users').then(users => {
      const maxEmployeeNumber = Math.max(...users.filter(({ username }) => username !== 'bewirtung').map(u => u.username.substr(1).length))
      setUsers(new Map(users.map(u => [
        u.username === 'bewirtung' ? u.username : fixedLengthNumber(u.username.substr(1), maxEmployeeNumber),
        u
      ])))
      setMaxEmployeeNumber(maxEmployeeNumber!)
    })
  }, [])

  // Handle button click
  const handleClick = useCallback((key: string|number) => {
    let _input = input

    if (typeof key === 'number') {
      _input += key
    } else if (key === 'cancel') {
      _input = ''
      setUser(undefined)
      setToasts(new Map())
    } else if (key === 'remove') {
      _input = _input.length > 0 ? _input.substr(0, _input.length - 1) : ''
    }

    setInput(_input)
    setEmployeeNumber(_input.substr(0, maxEmployeeNumber))
    setPin(_input.substr(maxEmployeeNumber!))
  }, [input, maxEmployeeNumber])

  // Test for employee number
  useEffect(() => {
    if (employeeNumber.length === maxEmployeeNumber && !users.has(employeeNumber)) {
      addToast(PersonXFill, 'Mitarbeiter nicht gefunden', `Mitarbeiter mit Nr. ${employeeNumber} existiert nicht!`)
      setEmployeeNumber('')
      setInput('')
    } else if (employeeNumber.length === maxEmployeeNumber && users.has(employeeNumber)) {
      setUser(users.get(employeeNumber))
    }
    // eslint-disable-next-line
  }, [employeeNumber])

  // Check PIN
  useEffect(() => {
    if (pin.length === 4) {
      api<{ token: string }>('@login', 'POST', undefined, {
        login: user!.username,
        password: pin + '0'
      })
        .then(({ token }) => {
          dispatch(setGlobalUser(user!))
          dispatch(setSession(token))
        })
        .catch(e => {
          if (e.status === 401 && e.reason.error?.type === 'Invalid credentials') {
            addToast(DoorClosedFill, 'Login gescheitert', 'Mitarbeiter-Nr. und PIN stimmen nicht überein.')
            setPin('')
            setInput(input.substr(0, input.length - 4))
          } else console.error(e)
        })
    }
    // eslint-disable-next-line
  }, [pin, dispatch])

  // Login als bewirtung
  const loginAsGuest = () => {
    api<{ token: string }>('@login', 'POST', undefined, {
      login: 'bewirtung',
      password: 'SuperSecretPassword'
    })
      .then(({ token }) => {
        dispatch(setGlobalUser(users.get('bewirtung')!))
        dispatch(setSession(token))
      })
      .catch(e => {
        addToast(DoorClosedFill, 'Login gescheitert', 'Login als Nutzer "Bewirtung" ist fehlgeschlagen.')
        console.error(e)
      })
  }

  return (
    <>
      <ToastContainer position='top-start' className='position-fixed m-2' style={{ zIndex: 9999 }}>
        {[...toasts.values()]}
      </ToastContainer>
      <div className='login w-100 vh-100 bg-black d-flex flex-column justify-content-center align-items-center' data-cucumber={Math.random() <= .05}>
        {users.size === 0
          ? <Spinner animation='border'/>
          : <>
            <h2 className='text-white fw-bold' ref={h2Ref}>Hi{user !== undefined ? `, ${user!.displayName.split(' ')[0]}` : ''}!</h2>
            <Form className='d-flex flex-column align-items-center'>
              <Row>
                <Form.Group>
                  <Form.Label
                    className='text-white fw-bold mx-auto d-block text-center'>{user === undefined ? 'Bitte Mitarbeiter-Nr. eingeben' : 'Bitte PIN eingeben'}</Form.Label>
                  <Container>
                    <div className='login-circles my-5' data-filled={pin.length.toString()}>
                      <span /><span /><span /><span />
                    </div>
                  </Container>
                </Form.Group>
              </Row>
              <div className='login-buttons mx-auto'>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 'cancel', 0, 'remove'].map(key =>
                  <Button
                    key={key}
                    variant='link'
                    className={`login-button login-button-${isNaN(key as number) ? key : 'number'} ${key === 'remove' ? '' : 'text-white '}text-decoration-none`}
                    onClick={() => handleClick(key)}
                  >
                    {key === 'cancel' ? 'Cancel' : key === 'remove' ? <Backspace/> : key}
                  </Button>
                )}
              </div>
              <Button key='guest' variant='link' className='login-guest text-white my-auto mt-5' onClick={loginAsGuest}>Als Gast
                bestellen</Button>
            </Form>
          </>
        }
      </div>
    </>
  )
}

export default Login
