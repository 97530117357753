import React, {FC, useEffect, useState} from 'react'
import {Beverage, Buy} from '../../PloneApi'
import {useSelector} from 'react-redux'
import {state} from '../../redux/reducers'
import {backendURL} from '../../helpers'
import {Col, Container, Row} from 'react-bootstrap'
import BeverageCard from './BeverageCard'
import {CupStraw, Grid, LightningFill} from 'react-bootstrap-icons'
import BeverageFilter from "./BeverageFilter";
import StatisticsCard from "./StatisticsCard";

const Dashboard: FC = () => {
    const greetings = [
        'Prost!',
        'Runter damit!',
        'Nachschub gefällig?!'
    ]

    const [showStatistic] = useState<Boolean>(Math.round(Math.random() * (5)) > 3)
    const [statisticPosition, setStatisticPosition] = useState<Number>(0)
    const [beverages, setBeverages] = useState<Beverage[]>([])

    const user = useSelector(({user}: state) => user)!
    const session = useSelector(({session}: state) => session)!
    const [greeting] = useState(greetings[Math.round(Math.random() * (greetings.length - 1))])

    useEffect(() => {
        fetch(`${backendURL}/get_beverages`, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`
            }
        }).then(async (res) => {
            if (res.status !== 200)
                await Promise.reject(await res.text())
            else return await res.json() as Beverage[]
        }).then(bevs => {
            setBeverages(bevs!)
            if (bevs)
                setStatisticPosition(Math.round(Math.random() * (bevs.length - 1)))
        })
    }, [session])


    const buy = async (ean: string, amount: number = 1, username: string = user.username): Promise<void> => {
        fetch(`${backendURL}/take_beverage`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${session}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ean,
                amount,
                user: username
            } as Buy.Request)
        }).then(async (res): Promise<Buy.Response> => {
            if (res.status !== 201) {
                return Promise.reject(await res.text())
            }
            return await res.json()
        }).then(resp => {
            setBeverages(prev => {
                return prev.map((bev): Beverage => {
                    if (bev.ean === resp.ean)
                        return {
                            ...bev,
                            stock: resp.stock
                        }
                    else return bev
                })
            })
        })
    }
    const getCard = (bev: Beverage, idx: number): JSX.Element => {
        if (idx === statisticPosition && showStatistic && user.showStatistics)
            return <>
                <StatisticsCard/>
                <BeverageCard beverage={bev} buy={buy}/>
            </>
        return   <BeverageCard beverage={bev} buy={buy}/>
    }

    return (
        <>
            <Container fluid className={"sticky"}>
                <Row>
                    <Col className={'dashboard-header'} xs={12}>{greeting}</Col>
                </Row>
                <Row className='beverage-filters'>
                    <Col xs={12} md={4} lg={2}>
                        <BeverageFilter Icon={Grid} type='Alle'/>
                    </Col>
                    <Col xs={12} md={4} lg={2}>
                        <BeverageFilter Icon={LightningFill} type='Energy'/>
                    </Col>
                    <Col xs={12} md={4} lg={2}>
                        <BeverageFilter Icon={CupStraw} type='Softdrinks'/>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    {beverages.map((bev,idx) => getCard(bev, idx))}
                </Row>
            </Container>
        </>
    )
}
export default Dashboard
