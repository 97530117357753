import { FC, useCallback, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { unsetUserSessionCart } from '../../redux/actions'

const AutoLogout: FC<{ initialTimeLeft?: number, showAt?: number }> = ({ initialTimeLeft = 200000000, showAt = 8 }) => {
  const start = useRef(NaN)
  const ref = useRef<HTMLProgressElement>(null)
  const [sec, setSec] = useState(initialTimeLeft)
  const reset = useRef(false)
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()

  const handleReset = useCallback(() => {
    reset.current = true
  }, [reset])

  const removeListeners = () => ['mousemove', 'mousedown', 'touchdown', 'touchmove'].forEach(evType =>
    window.removeEventListener(evType, handleReset)
  )

  useLayoutEffect(() => {
    const { current } = ref

    const animate = (now: number) => {
      if (isNaN(start.current)) start.current = now
      if (reset.current) {
        start.current = now
        reset.current = false
        setShow(false)
      }

      setSec(Math.floor(initialTimeLeft - (now - start.current) / 1e3))

      const m = (0 - 1e3) / (initialTimeLeft * 1e3 - (initialTimeLeft - showAt) * 1e3),
        b = 0 - m * initialTimeLeft * 1e3,
        x = now - start.current

      const ratio = m * x + b
      current!.value = ratio

      if (ratio <= 1e3 && !show) setShow(true)
      else if (ratio > 1e3 && show) setShow(false)

      if (ratio <= 0) {
        removeListeners()
        start.current = NaN
        dispatch(unsetUserSessionCart())
      } else {
        window.requestAnimationFrame(animate)
      }
    }

    window.requestAnimationFrame(animate)
    // eslint-disable-next-line
  }, []) // <-- do NOT remove empty dependency array, it will cause an infinite amount of requestAnimationFrame loops and freeze your browser

  useLayoutEffect(() => {
    ['mousemove', 'mousedown', 'touchdown', 'touchmove'].forEach(evType =>
      window.addEventListener(evType, handleReset)
    )
    return removeListeners
    // eslint-disable-next-line
  }, [])

  return (
    <div id='auto-logout' data-show={show}>
      <progress ref={ref} max={1000} value={1000} />
      <p>Sie werden in {sec} Sekunden ausgeloggt…</p>
    </div>
  )
}

export default AutoLogout
