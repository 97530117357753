import { DefaultRootState } from 'react-redux'
import { combineReducers } from 'redux'
import session from './session'
import user from './user'
import cart from './cart'
import { LoginUser as User, CartBeverage } from '../../PloneApi'

const reducers = combineReducers({
  session,
  user,
  cart
})

export default reducers

export type Reducer<T, R=T> = (state: T, action: { type: string, payload?: R }) => T
export type NullableReducer<T, R=T> = Reducer<T|null, R|null>

export type state = DefaultRootState & {
  session: string|null
  user: User|null
  cart: CartBeverage[]
}
