export default class Portrait {
    public readonly contentType: string
    public readonly data: string
    public readonly encoding: string = 'base64'

    constructor (public readonly base64string: string, public readonly filename: string) {
        [this.contentType, this.data] = base64string.split('base64,')
            .map((val, idx) => idx === 0 ? val.substring(val.indexOf('data:') + 6) : val)
    }

    public toString (): string {
        return this.base64string
    }

    public toObj (): Pick<Portrait, 'contentType'|'data'|'encoding'|'filename'> {
        return {
            contentType: this.contentType,
            data: this.data,
            encoding: this.encoding,
            filename: this.filename
        }
    }

    static async fromFile (file: File): Promise<Portrait> {
        return await new Promise((resolve, reject) => {
            const fr = new FileReader()
            fr.readAsDataURL(file)
            fr.onload = () => resolve(new Portrait(fr.result as string, file.name))
            fr.onerror = ev => reject(ev)
        })
    }
}