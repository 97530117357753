import { FC } from 'react'
import { Icon as IconT } from 'react-bootstrap-icons'
import {Button} from "react-bootstrap";

const BeverageFilter: FC<{ Icon: IconT, type: string, active?: boolean }> = ({ Icon, type, active }) => {
    return (
        <Button className={`filter ${active ? 'filter--active' : ''} filter-button`}>
            <Icon /> {type}
        </Button>
    )
}

export default BeverageFilter
