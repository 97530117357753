import { CartBeverage as Beverage } from '../../PloneApi'

const setCart = (cart: Beverage[]): Beverage[] => {
  localStorage.setItem('cart', JSON.stringify(cart))
  return cart
}

const cartReducer = <
  T extends 'CART_ADD'|'CART_REMOVE'|'CART_UPDATE_AMOUNT'|'CART_UPDATE_USER'|'CART_EMPTY'|'USER_SESSION_CART_UNSET',
  P = T extends 'CART_ADD'
    ? Beverage
    : T extends 'CART_REMOVE'
    ? number
    : T extends 'CART_UPDATE_AMOUNT'
    ? { index: number, amount: number }
    : T extends 'CART_UPDATE_USER'
    ? { index: number, user: string }
    : undefined
  > (
  state: Beverage[] = 'cart' in localStorage ? JSON.parse(localStorage.getItem('cart')!) : [] as Beverage[],
  { type, payload }: { type: T, payload: P }
) => {
  switch (type) {
    case 'CART_ADD':
      return setCart(state.concat(payload as unknown as Beverage))

    case 'CART_REMOVE':
      let rmIdx = (payload as unknown as number)
      if (rmIdx < 0 || rmIdx >= state.length)
        throw new RangeError('`payload must be a valid index of cart`')

      state.splice(rmIdx, 1)
      return setCart(state)

    case 'CART_UPDATE_AMOUNT':
      let updateAmountPayload = payload as unknown as { index: number, amount: number }
      if (updateAmountPayload.index < 0 || updateAmountPayload.index >= state.length)
        throw new RangeError('`index` must be a valid index of cart')
      if (updateAmountPayload.amount < 0)
        throw new RangeError('`amount` must be positive')
      
      state[updateAmountPayload.index].count = updateAmountPayload.amount
      return setCart(state)

    case 'CART_UPDATE_USER':
      let updateUserPayload = payload as unknown as { index: number, user: string }
      if (updateUserPayload.index < 0 || updateUserPayload.index >= state.length)
        throw new RangeError('`index` must be a valid index of cart')

      state[updateUserPayload.index].user = updateUserPayload.user
      return setCart(state)

    case 'CART_EMPTY':
    case 'USER_SESSION_CART_UNSET':
      localStorage.removeItem('cart')
      return []

    default:
      return state
  }
}

export default cartReducer
